/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { screening } from "../actions/auth.js";

import { loadUser } from "../actions/auth.js";
import {
  sendUserData,
  start_check,
  get_payment_method,
  clear_error,
} from "../actions/form";
import Icon from "@material-ui/icons/CheckCircleOutline";
import CancelIcon from "@material-ui/icons/Cancel";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    backgroundColor: "white",
    flexGrow: 1,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),

    "& > *": {
      margin: theme.spacing(1),
    },
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  imageSelector: {
    fontSize: "8rem",
  },
  line: {
    textAlign: "center",
    backgroundColor: "#fafafa",
    width: "100%",
    borderRadius: "10px",
    paddingLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    "& > label": {
      paddingLeft: theme.spacing(2),
    },
  },
  helpText: {
    height: "100%",
    display: "flex",
    textAlign: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  explanationText: {
    height: "auto",
    display: "flex",
    textAlign: "left",
    flexDirection: "column",
    fontSize: "13px",
  },
  explanationText_: {
    height: "auto",
    display: "flex",
    fontWeight: "bold",
    textAlign: "left",
    flexDirection: "column",
    fontSize: "15px",
    marginTop: "20px"
  },
  explanationValue: {
    height: "auto",
    display: "flex",
    textAlign: "left",
    flexDirection: "column",
    fontSize: "15px",
  },
  explanationValue_: {
    height: "auto",
    display: "flex",
    textAlign: "left",
    flexDirection: "column",
    fontSize: "15px",
    marginBottom: "20px"
  },
  underline: {
    "&::before": {
      borderBottom: "none",
    },
    "&::after": {
      borderBottom: "none",
    },
  },
  selectEmpty: {
    float: "left",
    width: "100%",
    borderRadius: "10px",
    height: "auto!important",
    "& > label": {
      paddingLeft: theme.spacing(1),
    },
  },
  addRowButton: {
    marginTop: theme.spacing(2),
  },
  selectFormControl: {
    width: "100%",
  },
  serviceTitle: {
    fontSize: "14px",
    color: "rgba(0, 0, 0, 0.7)",
    fontWeight: "600",
    marginBottom: theme.spacing(2),
  },
  dropzone: {
    marginTop: theme.spacing(4),
  },
  checkText: {
    marginTop: theme.spacing(1),
    color: "black",
  },
  submit: {
    margin: theme.spacing(4, 0, 1),
    height: "2.5rem",
    marginTop: "50px",
    borderRadius: '5px',
    backgroundColor: "#FF3D00!important",
    boxShadow: "none", // Removes shadow
    "&:hover": {
      boxShadow: "none",
      opacity: 0.85,
      backgroundColor: "#FF3D00", // Ensures color stays the same
    },
    "&:active": {
      boxShadow: "none", // Prevents elevation on click
    },
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(2, 0),
    },
  },

  submit_: {
    margin: theme.spacing(4, 0, 1),
    height: "2.5rem",
    marginTop: '20px',
    backgroundColor: "green",
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(2, 0),
    },
    //float: 'right',
  },
  submitt_: {
    margin: theme.spacing(4, 0, 1),
    height: "2.5rem",
    marginTop: '20px',
    backgroundColor: "red",
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(2, 0),
    },
    //float: 'right',
  },
  gridSubsection: {
    marginBottom: theme.spacing(1),
  },
  inputSmall: {
    fontSize: "13px",
    color: "#1b1f23",
    border: "1px solid #cfd7de",
    borderRadius: "5px",
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    "&::after": {
      borderBottom: "1px solid #949494",
    },
  },
  formLabel: {
    fontSize: "13px",
    color: "rgba(0, 0, 0, 0.5)",
    fontWeight: "600",
    marginBottom: theme.spacing(1),
  },
  formArea: {
    marginTop: theme.spacing(2),
  },
  paymentDivider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  inputSelect: {
    fontSize: "13px",
    color: "#1b1f23",
    border: "1px solid #cfd7de",
    borderRadius: "5px",
    padding: theme.spacing(1),
    width: "100%",
    marginTop: theme.spacing(1),
    "&::after": {
      borderBottom: "1px solid #949494",
    },
  },
}));

function BackgroundCheckSection(props) {
  const classes = useStyles()
  const [status, setStatus] = useState(true)

  // Add background check screen object to /me user object 
  useEffect(() => {
    props.loadUser()
    props.loadUser()
  }, [])

  // payments zone
  const [open] = useState(false)
  const descriptionElementRef = useRef(null)
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open])

  useEffect(() => {
    props.get_payment_method();
  }, [])

  useEffect(() => {
    if (props.form.isLoading === true) {
      props.get_payment_method();
      props.loadUser();
    }
  }, [props.form.isLoading]);

  useEffect(() => {
    // console.log(props.user.first_name)
    if (
      props.user.first_name !== "" &&
      props.user.last_name !== "" &&
      props.user.ssn !== "" &&
      props.user.date_of_birth !== "" &&
      props.user.address_line_1 !== "" &&
      props.user.city !== "" &&
      props.user.state !== "" &&
      props.user.country !== "" &&
      props.user.zipcode !== ""
    ) {
      setStatus(false);
      // console.log("not disabled")
    } else if (
      props.user.first_name === "" ||
      props.user.last_name === "" ||
      props.user.ssn === "" ||
      props.user.date_of_birth === "" ||
      props.user.address_line_1 === "" ||
      props.user.city === "" ||
      props.user.state === "" ||
      props.user.country === "" ||
      props.user.zipcode === ""
    ) {
      setStatus(true);
      // console.log("disabled")
    }
  }, [
    props.user.first_name,
    props.user.last_name,
    props.user.ssn,
    props.user.date_of_birth,
    props.user.address_line_1,
    props.user.city,
    props.user.state,
    props.user.country,
    props.user.zipcode,
  ])

  return (
    <Paper className={classes.root} elevation={0}>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Paper className={classes.paper} elevation={0}>
            <Grid container>
              <Grid item xs={12}>
                <Typography className={classes.explanationText}>
                  Pet owners are more likely to work with you if you have
                  completed a background check.
                </Typography>
                <Typography className={classes.explanationText}>
                  We use Checkr a third party for this service and charge a fee of 35 dollars.
                </Typography>

                <Typography className={classes.explanationText_}>
                  Background Check Status
                </Typography>
                <div
                  className={classes.explanationValue}
                  style={{
                    backgroundColor: "#d4edda", // Success green background
                    borderRadius: "10px",
                    display: "inline-block",
                    fontWeight: "bold",
                    padding: "8px",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    marginTop: "10px",
                    color: "#155724", // Success green text
                  }}
                >
                  Clear
                </div>

                <Typography className={classes.explanationText_}>
                  Background Check Report Assessment
                </Typography>
                <div
                  className={classes.explanationValue}
                  style={{
                    backgroundColor: "#d4edda", // Success green background
                    borderRadius: "10px",
                    display: "inline-block",
                    fontWeight: "bold",
                    padding: "8px",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    marginTop: "10px",
                    color: "#155724", // Success green text
                  }}
                >
                  Eligible
                </div>

                <Typography className={classes.explanationText_}>
                  Comments
                </Typography>
                <div
                  className={classes.explanationValue_}
                  style={{
                    display: "inline-block",
                  }}
                >
                  Background check has not been initiated
                </div>

                <div>

                  {props.user.background_check_status === "pending" &&
                    props.user.background_check_complete === false ? (
                    <Button
                      // type="submit"
                      disabled={status}
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      onClick={(e) => (window.location.href = "/wizard")}
                    >
                      {"Start Background check"}
                    </Button>
                  ) : (
                    ""
                  )}

                  {props.user.background_check_status === "started" &&
                    props.user.background_check_complete === false ? (
                    <Button
                      disabled
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                    // onClick={handleSubmit}
                    >
                      {"Background check pending..."}
                    </Button>
                  ) : (
                    ""
                  )}

                  {(props.user.background_check_status === "passed" || props.user.background_check_status === "Passed") &&
                    props.user.background_check_complete === true ? (
                    <Button
                      // type="submit"
                      // disabled={true}
                      variant="contained"
                      color="primary"
                      className={classes.submit_}
                      // onClick={(e) => (window.location.href = "/wizard")}
                      endIcon={<Icon></Icon>}
                    //onClick={handleClickOpen('paper')}
                    >
                      {"Background check cleared"}
                    </Button>
                  ) : (
                    ""
                  )}
                  {(props.user.background_check_status === "failed" || props.user.background_check_status === "Failed") &&
                    props.user.background_check_complete === true ? (
                    <Button
                      // type="submit"
                      // disabled={true}
                      variant="contained"
                      color="primary"
                      className={classes.submitt_}
                      // onClick={(e) => (window.location.href = "/wizard")}
                      endIcon={<CancelIcon></CancelIcon>}
                    //onClick={handleClickOpen('paper')}
                    >
                      {"Background check not cleared"}
                    </Button>
                  ) : (
                    ""
                  )}
                </div>

              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Paper>
  );
}

const mapStateToProps = (state) => ({
  screeningResults: state.screening.results,
  beingProcessed: state.screening.beingProcessed,
  user: state.auth.user,
  payment_methods: state.services.payment_methods,
  form: state.forms,
});

export default connect(mapStateToProps, {
  screening,
  loadUser,
  sendUserData,
  start_check,
  get_payment_method,
  clear_error,
})(BackgroundCheckSection);
