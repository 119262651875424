/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import { screening } from '../actions/auth.js';
import { loadUser } from '../actions/auth.js';
import { sendUserData, start_check, get_payment_method } from '../actions/form';
import { get_payout_schedule } from '../actions/booking';

import PaymentsViewListMakePayment from './PaymentsViewListMakePayment';
import PaymentMethodListBackCheckForm from './PaymentMethodListBackCheckForm';
// import PaymentBankAccounts from './PaymentBankAccounts';
import Spinner from '../assets/home_load.gif';

const useStyles = makeStyles((theme) => ({
  explanationText: {
    height: "auto",
    display: "flex",
    textAlign: "left",
    flexDirection: "column",
    fontSize: "13px",
  },
}));

function DashboardBackgroundCheckChoosePayment(props) {
  const classes = useStyles();
  const [show_not, setShow_not] = useState('');
  const [show_load, setLoading] = useState(false);

  const primary_method = (value) => {
    //console.log(value);
    props.primary_error(value);
  };

  const remove_form = () => {
    console.log('removing form...');
    setLoading(true);
    setShow_not(false);
    console.log(show_load);
    console.log(show_not);
  };

  const fetch_again = (e) => {
    props.get_payment_method();
    props.get_payment_method();
    props.loadUser();
    props.loadUser();
    props.get_payout_schedule();
  };

  let results = null;
  if (props?.payment_methods !== undefined && props?.payment_methods !== null) {
    results = props?.payment_methods;
  }

  // let payouts;
  // if (props.bank_accounts !== undefined && props.bank_accounts !== null) {
  //     payouts = props.bank_accounts;
  //   }

  React.useEffect(() => {
    // console.log(props.payment_methods.length)
    // props.get_payment_method();
    if (props?.payment_methods?.length > 0) {
      setShow_not(false);
      setLoading(false);
    }
    if (props?.payment_methods?.length === 0) {
      setShow_not(true);
    }
  }, [props.payment_methods]);

  React.useEffect(() => {
    props.get_payment_method();
    props.get_payout_schedule();
  }, []);

   React.useEffect(() => {
     if(props.bank_account_wizard){
        props.get_payment_method();
        props.get_payout_schedule();
        props.get_payout_schedule();
        props.loadUser();
        props.loadUser();
     }
   }, [props.bank_account_wizard]);

  return (
    <div>
      <Typography className={classes.explanationText}>
        Pet owners are more likely to work with you if you have completed a background check.
        {/* We shall add an icon and a link to Checkr here  */}
      </Typography>
      <Typography className={classes.explanationText}>
        We use Checkr a third party for this service and charge a fee of 35 dollars.
      </Typography>
      <br />
      

      {show_not === true && show_load === false ? (
        <div>
          <PaymentMethodListBackCheckForm
            remove_form={remove_form}
            payment_methods={results}
            fetch_again={fetch_again}
          />
        </div>
      ) : null}
      
      {/*{show_not === false && props.user.bank_accounts_count === 0 ? 
        <PaymentBankAccounts 
          wizard={true} 
          payment_methods={results} 
          bank_accounts={payouts}
          fetch_again={fetch_again} 
        />: null}*/}

      {/*{show_not === false && props.user.bank_accounts_count !== 0 ? (*/}
      {show_not === false ? (
        <div>
          <Typography className={classes.explanationText}>Select a Payment Method</Typography>
          <PaymentsViewListMakePayment
            primary_error={primary_method}
            payment_methods={results}
            fetch_again={fetch_again}
          />
        </div>
      ) : null}
      {show_load === true ? (
        <div>
          <div
            style={{
              display: 'flex',
              width: '100%',
              minHeight: '50vh',
              alignItems: 'center',
              justifyContent: 'center',
              paddingTop: '13px',
              background: '#fff'
            }}
          >
            <div
              style={{
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <img src={Spinner} alt="" height="90px" width="90px" />
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  screeningResults: state.screening.results,
  beingProcessed: state.screening.beingProcessed,
  errors: state.errors,
  payment_methods: state.services.payment_methods,
  user: state.auth.user,
  bank_accounts: state.booking.bank_accounts,
  bank_account_wizard: state.booking.bank_account_wizard,
});

export default connect(mapStateToProps, {
  screening,
  loadUser,
  sendUserData,
  get_payment_method,
  start_check,
  get_payout_schedule
})(DashboardBackgroundCheckChoosePayment);